// These must be the first lines in src/index.js
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'stylesheets/bootstrap.scss';
import 'stylesheets/application.scss';
import 'tippy.js/themes/light-border.css';

// Import this here so that it is included on initial page load.
// If we imported styles in Icons.js, styles would not apply until after the component rendered
// causing SVGs to be massive on initial page load until styles were added
import '@fortawesome/fontawesome-svg-core/styles.css';
/**
 * Legacy Stuff
 */
import 'jquery';
import 'popper.js';
import 'bootstrap';

/**
 * Modern Stuff
 */

import GlobalHeader from 'components/PageHeader/GlobalHeader';
import NewFooter from 'components/PageFooter/index';

import { init } from './base';

init({
  components: {
    GlobalHeader,
    NewFooter
  },
});
